
import { defineComponent, reactive, toRefs } from 'vue'
import Sticky from '@/components/sticky/Index.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    Sticky
  },
  setup() {
    const { t } = useI18n()
    const state = reactive({
      time: Date,
      url: '',
      platforms: ['a-platform'],
      platformsOptions: [
        { key: 'a-platform', name: 'platformA' },
        { key: 'b-platform', name: 'platformB' },
        { key: 'c-platform', name: 'platformC' }
      ],
      pickerOptions: {
        disabledDate(time: Date) {
          return time.getTime() > Date.now()
        }
      }
    })
    return {
      t,
      ...toRefs(state)
    }
  }
})
